import { useState, useEffect } from 'react';
import api from '../api';

const usePostApi = (url, initialData = null) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const postData = async (postData) => {
    try {
      setLoading(true);
      const response = await api.post('products/add-attribute/', {
        attribute: name,
        attribute_value: value,
      });
      if (response.app_data.StatusCode === 6001){
        setError(true)
      }
      else{
        setData(response.app_data.data)
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // If you want to perform an action after data is updated, you can do it here
    // For example, dispatch an action, update state, etc.
  }, [data]);

  return { data, error, loading, setName, setValue,postData, name, value };
};

export default usePostApi;
