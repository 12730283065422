import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/loader/loading_json.json';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
      />
    </div>
  );
};

export default Loader;
