import React from 'react';

const ErrorAlert = ({ isOpen, onClose, message }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bg-red-500 text-white p-4 text-center z-20 flex justify-center">
          <p>{message}</p>
          <button
            onClick={onClose}
            className="ml-2 p-2 bg-white text-red-500 rounded-full hover:bg-gray-200 focus:outline-none"
          >
            OK
          </button>
        </div>
      )}
    </>
  );
};

export default ErrorAlert;
