import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  IoChevronDownOutline,
  IoChevronForwardOutline,
  IoRemoveSharp,
} from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  activeRoute: {
    color:'#047857',
  },
}));
const SidebarSubMenu = ({ route }) => {
  const [open, setOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setOpen(!open);
  };

  
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <ListItem button onClick={handleSubMenuToggle}>
        <div style={{ minWidth: 33 }}>
          {/* Add your icon component here */}
          <span className="inline-flex items-center">
            <span className="mt-1 text-white">
              {open ? <IoChevronDownOutline /> : <IoChevronForwardOutline />}{" "}
            </span>{" "}
          </span>
        </div>
        <ListItemText className="text-white" primary={route.name} />
        {/* {open ? <IoChevronDownOutline /> : <IoChevronForwardOutline />} */}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {route.routes.map((child, i) => (
            <ListItem
              key={i + 1}
              button
              component={child?.outside ? "a" : NavLink}
              to={child.path}
              className={`text-sm hover:text-emerald-600`}
              activeClassName="text-emerald-600"
              // onClick={handleSubMenuToggle}
            >
              {child?.outside && (
                <ListItemIcon>
                  <IoRemoveSharp />
                </ListItemIcon>
              )}
              <ListItemText className={`text-white ml-8 ${location.pathname === child.path ? classes.activeRoute : ""}`} primary={child.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarSubMenu;
