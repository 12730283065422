import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import Dropdown from "../../components/utils/Dropdown";
import useGetApi from "../../hooks/useGetApi";
import Table from "../../components/utils/Table";
import DropdownStatus from "../../components/utils/DropdownStatus";
import api from "../../api";
import Pagination from "../../components/utils/Pagination";
import DropdownStatusFilter from "../../components/utils/DropdownStatusFilter";
import { Avatar, InputLabel } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { FaEdit } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { convertUTCStringToFormattedDate } from "../../utils/functions";
import { GiSandsOfTime } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { FaTruck } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { GiReturnArrow } from "react-icons/gi";
import { CiMenuKebab } from "react-icons/ci";
import { FaDownload } from "react-icons/fa6";
import DatePicker from "../../components/utils/DatePicker";
import useAuthStore from "../../store/UserStore";

const Orders = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { data: StatusData } = useGetApi("activities/admin/view-status/");
  const [showMenu, setShowMenu] = useState(false);
  const [actionModal, setActionModal] = useState("");
  console.log(actionModal, "___________action_____");
  const handleStatus = async (status, purchase) => {
    console.log(status.status, "______status____");
    api
      .post(`activities/admin/add-purchase-log/${purchase}/`, {
        status: status.status,
      })
      .then((res) => {
        console.log(res.app_data.StatusCode, "____");
        if (res.app_data.StatusCode === 6000) {
          setRefresh((prev) => !prev);
        }
      });
  };
  const navigate = useNavigate();
  console.log(StatusData, "_____statusData____");
  const action_data = {
    Pending: [
      {
        option: "Accept Order",
        icon: <TiTick />,
        filter: "Accepted",
      },
      {
        option: "Cancel Order",
        icon: <ImCancelCircle />,
        filter: "Cancelled",
      },
    ],
    Return: [
      {
        option: "Download Slip",
        icon: <FaDownload />,
      },
      {
        option: "Ready to ship",
        icon: <FaTruck />,
        filter: "Shipped",
      },
      {
        option: "Cancel Order",
        icon: <ImCancelCircle />,
        filter: "",
      },
    ],
    Cancelled: [
      {
        option: "Mark as Completed",
        icon: <TiTick />,
        filter: "Completed",
      },
    ],
  };
  const [showMenuMap, setShowMenuMap] = useState({}); // State variable to manage menu visibility for each row

  const toggleMenu = (rowId) => {
    setShowMenuMap((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId], // Toggle menu state for the clicked row
    }));
  };
  console.log(showMenuMap, "menu map");
  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice No",
        accessor: "invoice_no",
      },
      {
        Header: "Order Time",
        accessor: "id",
        Cell: (row) => {
          return (
            <span>{convertUTCStringToFormattedDate(row.row.created_at)}</span>
          );
        },
      },
      {
        Header: "Customer Name",
        accessor: "id",
        Cell: (row) => {
          console.log(row.customer_details, "__________row_____");
          return (
            <div className="flex space-x-3 items-center">
              <Avatar sx={{ bgcolor: deepOrange[500] }}>
                {row?.customer_details?.name}
              </Avatar>
              <div>
                <h4>{row.row.customer_details.name}</h4>
                <h4>{row.row.customer_details.phone}</h4>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Delivery Address",
        accessor: "id",
        Cell: (row) => {
          return (
            <div className="w-[150px] overflow-scroll scrollbar-hide">
              <h6>
                {row.row.address.address},<br />
                {row.row.address.street},<br />
                {row.row.address.city},{row.row.address.state},
                {row.row.address.post_code}
              </h6>
            </div>
          );
        },
      },
      {
        Header: "Method",
        accessor: "id",
        Cell: (row) => <td>online</td>,
      },
      {
        Header: "Amount",
        accessor: "total_amount",
      },
      {
        Header: "Status",
        accessor: "id",
        Cell: (row) => {
          console.log(row);
          return (
            <div className="flex items-center justify-center p-2 text-gray-300 bg-blue-500 rounded-md">
              {row?.row?.status === "Pending" ? (
                <GiSandsOfTime />
              ) : row?.row?.status === "Shipped" ? (
                <FaTruck />
              ) : row?.row?.status === "Accepted" ? (
                <TiTick />
              ) : row?.row?.status === "Delivered" ? (
                <TiTick />
              ) : row?.row?.status === "Cancelled" ? (
                <ImCancelCircle />
              ) : row?.row?.status === "Return" ? (
                <GiReturnArrow />
              ) : null}
              <span className="ml-3">{row?.row?.status}</span>
            </div>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "id",
      //   Cell: (row) => {
      //     return (
      //       <div className="w-full items-center justify-center cursor-pointer relative" onClick={() => setShowMenu(!showMenu)}>
      //         <CiMenuKebab /> {/* Menu icon */}
      //         {showMenu && (
      //           <div className="absolute bg-gray-600 p-2 rounded" style={{ width: "180px", left: "-40px" }}>
      //             <h4>Hello</h4> {/* Content of the contextual menu */}
      //           </div>
      //         )}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (row) => {
          const isMenuOpen = showMenuMap[row.row.id] || false; // Check if menu is open for this row
          console.log(row.row.status, "_______status____");
          const handleStatus = (filter) => {
            api
              .post(`activities/admin/add-purchase-log/${row.row.id}/`, {
                status: filter,
              })
              .then((res) => {
                alert("Updated successfully");
                setRefresh((prev) => !prev);
              });
          };
          return (
            <div className="flex item-center justify-center gap-2 relative">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`${row.row.id}`)}
              >
                <GrView />
              </span>
              {row?.row?.status === "Pending" ? (
                <div className="relative">
                  <span
                    className="cursor-pointer"
                    onClick={() => toggleMenu(row.row.id)}
                  >
                    <CiMenuKebab /> {/* Menu icon */}
                  </span>
                  {isMenuOpen && (
                    <div
                      className="absolute bg-gray-600 p-2 rounded"
                      style={{ width: "180px", left: "-127px", zIndex: 10 }}
                    >
                      {action_data[row.row.status]?.map((item) => (
                        <div
                          className="py-2 text-center flex items-center justify-center gap-2 cursor-pointer hover:bg-gray-500"
                          onClick={() => handleStatus(item.filter)}
                        >
                          {item.icon}
                          {item.option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          );
        },
      },
    ],
    [showMenuMap]
  );
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // const [refresh,setRefresh] = useState(false)
  const [filterOrder, setFilterOrder] = useState("All");
  // const [refresh,setRefresh] = useState(false)
  const { data, pagination, setPage, response } = useGetApi(
    "activities/admin/orders/",
    refresh,
    {
      filter: filterOrder,
      start_date:startDate,
      end_date:endDate,

    }
  );
  const [activeTab, setActiveTab] = useState("all");
  const startOrdDate = useAuthStore.getState().orderStartDate;
  const endOrdDate = useAuthStore.getState().orderEndDate;
  console.log(startOrdDate,"____ord_date");
  useEffect(()=>{
    if (startOrdDate){
      setStartDate(startOrdDate)
    }
    if (endDate){
      setEndDate(endOrdDate)
    }
    console.log(startDate,"start____");
  },[startOrdDate])
  
  const handleDateChange = (date) => {
    setStartDate(date);
    useAuthStore.getState().setOrderStartDate(date)
    setRefresh((prev)=>!prev)
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    useAuthStore.getState().setOrderEndDate(date)
    setRefresh((prev)=>!prev)
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <div className="py-3 flex justify-between">
        <h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
          Orders
        </h3>
        <div className="flex gap-10">
          <div className="flex items-center gap-4">
            <label style={{ color: "#fff",whiteSpace:'nowrap' }}>Start date</label>
            <DatePicker
              selectedDate={startDate}
              onDateChange={handleDateChange}
              required
            />
          </div>
          <div className="flex items-center gap-4">
            <label style={{ color: "#fff",whiteSpace:'nowrap' }}>End date</label>
            <DatePicker
              selectedDate={endDate && endDate}
              onDateChange={handleEndDateChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-5 p-4 bg-gray-800 rounded">
        <div className="switch-case-container">
          <ul className="switch-case-buttons">
            {[
              "All",
              "Pending",
              "Accepted",
              "Shipped",
              "Delivered",
              "Cancelled",
              "Return",
            ]?.map((tab) => (
              <li
                key={tab}
                className={`switch-case-button ${
                  activeTab === tab ? "active" : ""
                }`}
                onClick={() => {
                  handleTabClick(tab);
                  setFilterOrder(tab);
                  setRefresh((prev) => !prev);
                }}
              >
                {tab}(
                {tab === "All"
                  ? response.all_count
                  : tab === "Pending"
                  ? response.pending_orders
                  : tab === "Accepted"
                  ? response.accepted_count
                  : tab === "Shipped"
                  ? response.shipped_count
                  : tab === "Delivered"
                  ? response.delivered_count
                  : tab === "Cancelled"
                  ? response.cancelled_count
                  : tab === "Return"
                  ? response.Return_count
                  : 0}
                )
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="mt-3 rounded overflow-scroll">
        {data && data.length > 0 ? (
          <Table data={data} columns={columns} />
        ) : (
          <h3 className="text-white">No data found</h3>
        )}
        <Pagination
          totalPages={pagination.total_pages}
          currentPage={pagination.current_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </div>
  );
};

export default Orders;
