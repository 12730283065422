import React from 'react'
import useGetApi from '../../hooks/useGetApi'
import Table from '../../components/utils/Table';
import Pagination from '../../components/utils/Pagination';
import { useNavigate } from 'react-router-dom';

const Refferals = () => {
    const {data,pagination,setPage} = useGetApi('activities/admin-refferals/')
    const navigate = useNavigate()
    console.log(data,'________');
    const columns = React.useMemo(
        () => [
          {
            Header: "Reffered by",
            accessor: "referred_by",
          },
          {
            Header: "Reffered Link",
            accessor: "referral_link",
          },
          {
            Header: "Clicks",
            accessor: "id",
            Cell:({row})=>{
                return (
                    <button onClick={()=>navigate(`/referals/${row.id}`)} className='px-3 py-2 bg-gray-300 text-gray-900 rounded' >Clicks</button>
                )
            }
          },
          {
            Header: "product",
            accessor: "product",
          },
          {
            Header: "Total Earning",
            accessor: "total_earning",
          },
        ],
        []
      );
  return (
    <div>
      <div>
        <h4 className='text-white text-3xl font-bold py-3'>Refferals</h4>
      </div>
      <Table data={data} columns={columns} />
      <Pagination
          totalPages={pagination?.total_pages}
          currentPage={pagination?.current_page}
          onPageChange={(e) => setPage(e)}
        />
    </div>
  )
}

export default Refferals
