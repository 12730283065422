import React, { useState } from "react";
import useUploader from "../../hooks/useUploader";
import api from "../../api";

const ImageUploader = ({ dataId, varientId, setVarientId, onChange }) => {
  console.log(varientId,'_______var_id');
  const { uploadFile, uploadedFileUrl, uploadProgress, uploadError } =
    useUploader();
  const [imagePreviews, setImagePreviews] = React.useState([]);
  const [thumbnailIndex, setThumbnailIndex] = React.useState(null);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    selectedImages.forEach((image) => uploadFile(image));
  };

  React.useEffect(() => {
    if (uploadedFileUrl) {
      setImagePreviews((prevPreviews) => {
        const newPreviews = prevPreviews.concat({
          url: uploadedFileUrl,
          thumbnail: prevPreviews.length === 0, // Sets the first uploaded image as the thumbnail by default
        });
        onChange(newPreviews); // Move onChange here to ensure the latest state is used
        return newPreviews;
      });
    }
  }, [uploadedFileUrl]);
  console.log(imagePreviews, "-----preview");

  const removeImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  const handleThumbnailChange = (index) => {
    setThumbnailIndex(index);
    const updatedPreviews = imagePreviews.map((preview, idx) => ({
      ...preview,
      thumbnail: idx === index,
    }));
    setImagePreviews(updatedPreviews);
  };
  // const [varientId,setVarientId]=useState()
  const handleUpload = () => {
    const selectedImages = imagePreviews.map((preview) => ({
      image: preview.url,
      thumbnail: preview.thumbnail,
    }));
    console.log(selectedImages, "_____images");
    api
      .post(`products/admin/add-product-varient/${dataId}/`, {
        data: selectedImages,
      })
      .then((res) => {
        setVarientId(res.app_data.data.id);
      });
    // Send selectedImages to API or use it as needed
  };

  return (
    <div className="flex flex-wrap gap-4">
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
        id="imageUpload"
      />
      <label
        htmlFor="imageUpload"
        className="cursor-pointer border border-gray-300 rounded-md px-4 py-2 text-center"
        style={{ height: "45px" }}
      >
        Upload Images
      </label>
      {imagePreviews.map((preview, index) => (
        <div
          key={index}
          className="relative"
          style={{ width: "80px", height: "80px" }}
        >
          <img
            src={preview.url}
            alt={`Preview ${index}`}
            className="w-full h-full object-cover rounded-md"
          />
          <div className="absolute top-0 right-0 flex flex-col items-center justify-center">
            <input
              type="radio"
              name="thumbnail"
              checked={thumbnailIndex === index}
              onChange={() => handleThumbnailChange(index)}
              className="mx-auto focus:ring-gray-500"
            />
            <button
              onClick={() => removeImage(index)}
              className="bg-white rounded-full border border-gray-300 p-1 text-gray-500 focus:outline-none"
              style={{ marginTop: "5px" }}
            >
              &times;
            </button>
          </div>
        </div>
      ))}
      {/* <button
        
        onClick={handleUpload}
        className={`bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none ${varientId && 'cursor-not-allowed'}`}
        style={{ width: "150px", height: "50px" }}
      >
        {varientId ? 'Done' : 'Upload'}
      </button> */}
      {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
      {uploadError && <p>Error: {uploadError}</p>}
    </div>
  );
};

export default ImageUploader;
