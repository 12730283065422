import React from 'react';

const InputNumber = ({ value, onChange ,placeholder=""}) => {
  const handleChange = (e) => {
    const input = e.target.value;
    // Regex to allow only numbers
    const regex = /^\d*$/;
    // Check if the input matches the regex pattern
    if (regex.test(input) || input === '') {
      onChange(input);
    }
  };

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      className="text-white border border-gray-600 bg-gray-700 w-full rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-500"
      placeholder={placeholder}
      style={{ WebkitAppearance: 'textfield', MozAppearance: 'textfield' }}
    />
  );
};

export default InputNumber;
