import React from 'react'
import { GiWallet } from "react-icons/gi";

const AccountCard = ({title,amount}) => {
  return (
    <div className='w-1/3 p-4 bg-gray-800 rounded shadow-xs shadow-white flex justify-between items-center'>
      <div>
        <h6 className='text-gray-300'>{title}</h6>
        <h3 className='text-gray-300 font-bold text-3xl'>र{" "}{amount}</h3>
      </div>
      <div className='rounded-full flex justify-center items-center bg-gray-400' style={{width:'80px',height:'80px'}}>
        <GiWallet color="green" size={30}/>
      </div>
    </div>
  )
}

export default AccountCard
