import { useEffect, useState } from 'react';
import api from '../api';
import useAuthStore from '../store/UserStore';

const useProductView = (search,category) => {
  const [product, setProduct] = useState('');
  const [error,setError] = useState()
  const [loading,setLoading] = useState()
  const [refresh,setRefresh] = useState(false)
  const [page,setPage]=useState()
  const [pagination,setPagination]=useState({})
useEffect(()=>{
    api.get('products/admin/product/',
    {
      params:{
        search:search,
        category:category.id,
        page:page
      }
    }
    ).then((res)=>{
        setProduct(res.app_data.data)
        setPagination(res.app_data.pagination_data)
    })
},[search,category,refresh,page])

  return {
    product,
    loading,
    error,
    setRefresh,
    pagination,
    setPage
  };
};

export default useProductView;
