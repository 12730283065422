import React from 'react';

const InputField = ({ placeholder, ...props }) => {
  return (
    <input
      className="block text-white w-full h-12 border px-3 py-1 text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md bg-gray-700 focus:bg-gray-700 focus:border-gray-200 border-gray-600 focus:border-gray-500 dark:bg-gray-700"
      type="text"
      placeholder={placeholder}
      {...props}
    />
  );
};

export default InputField;
