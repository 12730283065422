import { useEffect, useState } from 'react';
import api from '../api';
import useAuthStore from '../store/UserStore';

const useCategoryView = (refresh) => {
  const [category, setCategory] = useState('');
  const [error,setError] = useState()
  const [loading,setLoading] = useState()

//   
useEffect(()=>{
    api.get('products/admin-category/').then((res)=>{
        setCategory(res.app_data.data)
    })
},[refresh])

  return {
    category,
    loading,
    error
  };
};

export default useCategoryView;
