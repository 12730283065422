import { useState } from 'react';
import axios from 'axios';
import api from '../api';

const useAddSubCategory = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryData, setSategoryData]=useState()

  const postSubCategory = async (productData) => {
    setLoading(true);
    try {
      const response = await api.post('products/admin/add-subcategory/', categoryData);
      setLoading(false);
      return response.data; // You can handle the response data as needed
    } catch (err) {
      setLoading(false);
      setError(err.message || 'Failed to post product data');
      return null;
    }
  };

  return { loading, error, setSategoryData,postSubCategory };
};

export default useAddSubCategory;
