import React, { useState } from "react";

const TextEntry = ({ entries, onAddEntry, onRemoveEntry }) => {
  const [newEntry, setNewEntry] = useState("");

  const handleAddEntry = () => {
    if (newEntry.trim() !== "") {
      onAddEntry(newEntry);
      setNewEntry("");
    }
  };

  const handleRemoveEntry = (index) => {
    onRemoveEntry(index);
  };

  return (
    <div>
      <div className="my-4">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={newEntry}
            onChange={(e) => setNewEntry(e.target.value)}
            className="border border-gray-600 bg-gray-700 rounded px-2 py-1 w-48 text-gray-400"
          />
          <button
            onClick={handleAddEntry}
            className="bg-blue-500 text-white px-4 py-1 rounded"
          >
            Add
          </button>
        </div>
      </div>
      <ul className="mt-2">
        {entries?.map((entry, index) => (
          <li key={index} className="flex items-center space-x-2">
            <span className="text-gray-400">{entry}</span>
            <button
              onClick={() => handleRemoveEntry(index)}
              className="text-red-500"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TextEntry;
