import React, { useState } from "react";
import AccountCard from "../../components/utils/AccountCard";
import Table from "../../components/utils/Table";
import { BiMenuAltRight } from "react-icons/bi";
import useGetApi from "../../hooks/useGetApi";
import { convertUTCStringToFormattedDate } from "../../utils/functions";
import Pagination from "../../components/utils/Pagination";
import DatePicker from "../../components/utils/DatePicker";

const Accounts = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setRefresh((prev) => !prev);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Details",
        accessor: "id",
        Cell: (row) => {
          return (
            <div>
              <h3>{row.row.user_details.name}</h3>
              <h4>{row.row.user_details.phone_number}</h4>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Date",
        accessor: "id",
        Cell: (row) => {
          console.log(row.row.created_at, "+++created_at");
          return (
            <span>{convertUTCStringToFormattedDate(row.row.created_at)}</span>
          );
        },
      },
      {
        Header: "Transaction",
        accessor: "id",
        Cell: (row) => {
          return <span>{row.row.credit ? "Credited" : "Debited"}</span>;
        },
      },
    ],
    []
  );
  const [refresh, setRefresh] = useState(false);
  const { data, setPage } = useGetApi("activities/admin/accounts/", refresh, {
    filter: activeTab,
    start_date: startDate,
    end_date: endDate,
  });

  const handleDateChange = (date) => {
    setStartDate(date);
    setRefresh((prev) => !prev);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setRefresh((prev) => !prev);
  };
  console.log(data, "new __data__");
  return (
    <div>
      <div className="py-2">
        <h4 className="text-gray-300 font-bold text-3xl">Accounts</h4>
      </div>
      <div className="flex w-full space-x-5 ">
        <AccountCard title="Total Credited" amount={data?.credited} />
        <AccountCard
          title="Total Debited"
          amount={data?.debited ? data?.debited : 0}
        />
        {/* <AccountCard
          title="Total Withdrawel"
          amount={data?.withdrawel ? data?.withdrawel : 0}
        /> */}
      </div>
      <div className="py-3">
        <h4 className="text-gray-300 font-bold text-2xl">Payments</h4>
      </div>
      <div className="switch-case-container flex justify-between items-center">
        <ul className="switch-case-buttons">
          {["All", "Credit", "Debit"].map((tab) => (
            <li
              key={tab}
              className={`switch-case-button ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="flex gap-10">
          <div className="flex items-center gap-4 w-[400px]">
            <label style={{ color: "#fff", whiteSpace: "nowrap" }}>
              Start date
            </label>
            <DatePicker
              selectedDate={startDate}
              onDateChange={handleDateChange}
              required
            />
          </div>
          <div className="flex items-center gap-4 w-[400px]">
            <label style={{ color: "#fff", whiteSpace: "nowrap" }}>
              End date
            </label>
            <DatePicker
              selectedDate={endDate}
              onDateChange={handleEndDateChange}
              required
            />
          </div>
        </div>
      </div>
      <div>
        <Table columns={columns} data={data?.transactions} pageSize={5} />
        <Pagination
          totalPages={data?.pagination_data?.total_pages}
          currentPage={data?.pagination_data?.current_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </div>
  );
};

export default Accounts;
