import React from 'react'

const DashboardMainCard = ({icon,title,datas,price,color}) => {
  return (
    <div className='bg-black w-full py-8 rounded"' style={{backgroundColor:color}}>
      <div className='flex justify-center ' style={{fontSize:'30px',color:'#fff'}}>{icon}</div>
      <div><h3 className='text-center text-lg font-bold text-white'>{title}</h3></div>
      <div><h2 className='text-center text-lg font-bold text-white'>₹{datas}</h2></div>
    </div>
  )
}

export default DashboardMainCard