import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from '../../store/UserStore';


const AppRoute = ({children}) => {
    const token = useAuthStore((state) => state.token);
    return token ? children : <Navigate to='/auth' />;
};

export default AppRoute;