import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthRouter from './AuthRouter'
import AuthRoute from '../routes/AuthRoute'
import AppRoute from '../routes/AppRoute'
import Layout from '../../pages/dashboard/Layout'
import NotFound from '../../pages/NotFound'

const MainRouter = () => {
  return (
    <Routes>
        <Route path='/auth/*' element={<AuthRoute><AuthRouter/></AuthRoute>}/>
        <Route path='/*' element={<AppRoute><Layout/></AppRoute>}/>
        <Route path='*' element={<NotFound/>}/>
    </Routes>
  )
}

export default MainRouter