import React, { useState } from "react";
import InputField from "../../components/utils/InputField";
import useGetApi from "../../hooks/useGetApi";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Table from "../../components/utils/Table";
import Modal from "../../components/utils/Modal";
import InputLabel from "../../components/utils/InputLabel";
import InputNumber from "../../components/utils/InputNumber";
import TextAreaInput from "../../components/utils/TextAreaInput";
import { Button } from "@windmill/react-ui";
import api from "../../api";
import DatePicker from "../../components/utils/DatePicker";
import { formatISODate } from "../../utils/functions";
import RangeInput from "../../components/RangeInput";

const Coupens = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { data, error, loading } = useGetApi("marketing/admin/get-coupen/", refresh);
  const [offer, setOffer] = useState();
  const [description, setDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [offerStart, setOfferStart] = useState();
  const [offerEnd, setOfferEnd] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [editData, setEditData] = useState({});
  const [editModal, setEditModal] = useState(false);

  const handleAdd = () => {
    api
      .post("marketing/admin/add-coupen/", {
        offer,
        description,
        validity: formatISODate(selectedDate),
        offer_price: parseInt(offerPrice),
        offer_start_price: offerStart,
        offer_end_price: offerEnd,
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setOffer();
          setDescription("");
          setModal(false);
          setRefresh((prev) => !prev);
        }
      });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleEdit = () => {
    api.post(`marketing/admin/edit-coupen/${editData.id}/`, editData).then((res) => {
      console.log(res);
      setRefresh((prev) => !prev);
      setEditModal(false);
    });
  };
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "description",
        accessor: "description",
      },
      {
        Header: "code",
        accessor: "code",
      },
      {
        Header: "Valid till ",
        accessor: "validity",
      },
      {
        Header: "Offer percent",
        accessor: "offer",
      },
      {
        Header: "Offer Starts",
        accessor: "offer_start_price",
      },
      {
        Header: "Offer End Price",
        accessor: "offer_end_price",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (row) => {
          console.log(row.row.id, "row_______");
          const deleteCoupen = () => {
            api.delete(`marketing/admin/delete-coupen/${row.row.id}`).then((res) => {
              // setEditModal(false)
              setRefresh(!refresh);
              alert("Deleted coupon successfully")
            });
          };
          return (
            <div className="flex item-center justify-center gap-2">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setEditData(row.row);
                  setEditModal(true);
                }}
              >
                <FaEdit />
              </span>
              <span className="cursor-pointer" onClick={() => deleteCoupen()}>
                <MdDelete />
              </span>
              {/* <span className="cursor-pointer" onClick={() => navigate(`/products/${row.row.id}`)}>
                <GrView />
              </span> */}
            </div>
          );
        },
      },
    ],
    []
  );
  console.log(editData, "_____");
  const handleDelete = () => {
    api.delete(`marketing/admin/delete-coupen/${editData.id}`).then((res) => {
      setEditModal(false);
      setRefresh(!refresh);
    });
  };
  console.log(data, "data");
  return (
    <div>
      <div className="py-3">
        <h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
          Coupons
        </h3>
      </div>
      <div className="bg-gray-800 rounded p-4 flex gap-5">
        {/* <div className="w-1/4">
          <InputField placeholder={"Search Product"} />
        </div>
        <div className="w-1/5">
          <InputField placeholder={"Category"} />
        </div>
        <div className="w-1/4">
          <InputField placeholder={"Price"} />
        </div> */}

        <div className="w-full flex justify-end gap-2">
          {/* <button className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-emerald-500 border border-transparent active:bg-emerald-600 hover:bg-emerald-600 h-12 w-1/2 bg-emerald-700">
            Filter
          </button> */}
          <button
            className="align-bottom flex w-1/5 items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-1/2 mr-3 flex items-center justify-center cursor-pointer h-12 px-4 md:py-1 py-2 h-12 text-sm bg-gray-700"
            onClick={() => setModal(true)}
          >
            Add Coupens
          </button>
        </div>
      </div>
      <div className="mt-4">
        <Table columns={columns} data={data} />
      </div>
      <Modal title={"Add Coupens"} isOpen={modal} onClose={() => setModal(false)}>
        <div>
          <div className="flex" style={{ width: "500px" }}>
            <InputLabel label={"Offer"} />
            <InputNumber value={offer} onChange={(e) => setOffer(e)} required={!offerPrice} />
          </div>
          <div className="flex mt-5" style={{ width: "500px" }}>
            <InputLabel label={"Offer Price"} />
            <InputNumber value={offerPrice} onChange={(e) => setOfferPrice(e)} required={!offer} />
          </div>
          <div className="flex mt-5">
            <InputLabel label={"Description"} />
            <TextAreaInput value={description} onChange={(e) => setDescription(e.target.value)} required />
          </div>
          <div className="flex py-3">
            <InputLabel label={"Validity"} />
            <DatePicker selectedDate={selectedDate} onDateChange={handleDateChange} required />
          </div>
          <div className="flex py-3">
            <InputLabel label={"Price range"} />
            <RangeInput
              onStartChange={(e) => {
                setOfferStart(e.target.value);
                console.log(e.target.value);
              }}
              start={offerStart}
              onEndChange={(e) => setOfferEnd(e.target.value)}
              end={offerEnd}
              required
            />
          </div>
          <div className="flex justify-end">
            <Button
              style={{ backgroundColor: "orange" }}
              onClick={() => {
                if (!offer && !offerPrice) {
                  alert("Either Offer or Offer Price is required.");
                } else if (!description || !selectedDate || !offerStart || !offerEnd) {
                  alert("All fields are required.");
                } else {
                  handleAdd();
                }
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={editModal} title={"Edit Coupon"} onClose={() => setEditModal(false)}>
        <div className="flex w-[500px] py-3">
          <InputLabel label="Offer Percentage" />
          <InputNumber value={editData?.offer} onChange={(e) => setEditData((prev) => ({ ...prev, offer: e }))} />
        </div>
        <div className="flex w-[500px] py-3">
          <InputLabel label="Offer Price" />
          <InputNumber
            value={editData.offer_price}
            onChange={(e) => setEditData((prev) => ({ ...prev, offer_price: e }))}
          />
        </div>
        <div className="flex w-[500px] py-3">
          <InputLabel label="Offer Start Price" />
          <InputNumber value={editData?.offer_start_price} onChange={(e) => setEditData((prev) => ({ ...prev, offer_start_price: e }))} />
        </div>
        <div className="flex w-[500px] py-3">
          <InputLabel label="Offer End Price" />
          {/* <InputNumber value={editData?.offer_end_price} onChange={(e) => setEditData((prev) => ({ ...prev, offer_end_price: e }))} /> */}
          <InputNumber value={editData?.offer_end_price} onChange={(e) => setEditData((prev) => ({ ...prev, offer_end_price: e }))} />
        </div>
        <div className="flex w-[500px] py-3">
          <InputLabel label="Offer Ends on" />
          <DatePicker
            selectedDate={new Date(editData.validity)}
            onDateChange={(date) => setEditData((prev) => ({ ...prev, validity: date }))}
            required
          />
        </div>
        <div className="space-x-4">
          <button
            className="bg-gray-800 py-1 px-6 rounded text-white shadow-md hover:shadow-gray-500 "
            onClick={() => handleEdit()}
          >
            Submit
          </button>
          <button
            className="bg-[red] py-1 px-6 rounded text-white shadow-md hover:shadow-gray-500 "
            onClick={() => handleDelete()}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Coupens;
