import React from 'react'

const OrderCard = ({icon,title ,value,color}) => {
  return (
    <div className='w-full bg-gray-800 p-4 rounded flex gap-4' >
        <div className="flex flex-col items-center justify-center text-white rounded-full" style={{width:'50px',height:'50px',fontWeight:600, backgroundColor:color}}>
            {icon}
        </div>
        <div>
            <h4 className='font-bold text-gray-400'>{title}</h4>
            <h5 className='font-medium text-white'>{value}</h5>
        </div>
    </div>
  )
}

export default OrderCard