// TableHome.js
import React, { useState } from "react";
import PaginatorComp from "../PaginatorComp";
import NoDataFound from "../../assets/img/notfound.png";

const TableHome = ({ columns, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10; // Replace with your total pages

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Fetch data for the new page or perform other actions
  };

  return (
    <>
      <table className="min-w-full border border-gray-500 border-solid divide-y divide-gray-200 rounded-2">
        <thead className="bg-gray-800 rounded">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-white uppercase"
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-500">
          {data && data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="">
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-6 py-4 text-sm text-white whitespace-nowrap"
                  >
                    {column.accessor === "id" && column.Cell
                      ? column.Cell({ row })
                      : row[column.accessor]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="py-4 text-center">
                <div className="flex flex-col items-center justify-center">
                  <img src={NoDataFound} alt="No Data" className="mt-2" />
                  <p className="mb-2 text-gray-500">Oops, no data found</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* {data && data.length > 0  && (
        <PaginatorComp
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )} */}
    </>
  );
};

export default TableHome;
