import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchDropDown = ({ options, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  console.log(options);
  useEffect(() => {
    // Filter options based on the search term
    const filterOptions = options?.filter(option =>
      option?.name?.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filterOptions);
    setIsDropdownOpen(searchTerm !== '' && filterOptions.length > 0);
  }, [searchTerm, options]);

  const handleSelect = (selectedOption) => {
    onSelect(selectedOption);
    setSearchTerm(selectedOption); // Update the search term with the selected option
    setIsDropdownOpen(false); // Close the dropdown
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder="Search..."
        className="border border-gray-500 rounded-md p-2 focus:outline-none w-full bg-gray-700 text-white"
        value={searchTerm.name}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsDropdownOpen(searchTerm !== '' && filteredOptions.length > 0)}
      />
      {isDropdownOpen && (
        <ul className="absolute z-10 bg-gray-700 border border-gray-500 mt-1 w-full rounded-md divide-y">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 cursor-pointer bg-gray-700 hover:bg-gray-600 text-white"
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SearchDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchDropDown;
