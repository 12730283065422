// import { Avatar,Icon } from "@windmill/react-ui";
import Avatar from "@mui/material/Avatar";
import { blue } from "@material-ui/core/colors";
import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { IoSunnySharp } from "react-icons/io5";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from '@mui/icons-material/Logout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useLogout();
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Handle logout logic
    handleClose();
  };

  const handleEditAccount = () => {
    // Handle edit account logic
    navigate('/edit-profile')
    handleClose();
  };
  return (
    <div
      className="h-45 bg-gray-800"
      style={{ height: "50px", alignItems: "center" }}
    >
      <div className="h-full flex gap-4 justify-end pr-6">
        {/* <div
          className="h-full"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#12b981",
            fontSize: 25,
          }}
        >
          <IoSunnySharp />
        </div>
        <div
          className="h-full"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#12b981",
            fontSize: 25,
          }}
        >
          <IoNotifications />
        </div> */}
        <div className="relative py-2">
          <Avatar
            sx={{ bgcolor: "blue[500]", cursor: "pointer" }}
            onClick={handleClick}
          >
            A
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleEditAccount}><DriveFileRenameOutlineIcon/>Edit Account</MenuItem>
            <MenuItem onClick={logout}><LogoutIcon/>{" "}Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
