function formatISODate(inputISODate) {
    const dateObject = new Date(inputISODate);
    const formattedDate = dateObject.toISOString().split('T')[0];
    return formattedDate;
  }

  function convertUTCStringToFormattedDate(utcString) {
    const date = new Date(utcString);
    
    // Get the day, month, and year
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    
    // Get the hours and minutes
    let hours = date.getHours();
    let minutes = date.getMinutes();
    
    // Convert hours to 12-hour format and add "am" or "pm"
    const amPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight
    minutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero for single-digit minutes
    
    // Construct the formatted date string
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;
    
    return formattedDate;
  }

export {formatISODate, convertUTCStringToFormattedDate}