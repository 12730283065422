import { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../api';

const useGetApi = (url,refresh,params) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allData,setAllData]=useState()
  const [page,setPage]=useState()
  const [pagination,setPagination]=useState({})
  const [response, setResponse] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(url,{
          params:{
            page:page,
            ...params
          }
        });
        setData(response.app_data.data);
        setAllData(response.app_data)
        setResponse(response.app_data)
        setPagination(response.app_data.pagination_data)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url,refresh,page]);

  return { data, loading, error,allData,setPage,pagination,response };
};

export default useGetApi;
