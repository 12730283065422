import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import Table from "../../components/utils/Table";
import useProductView from "../../hooks/useProductView";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Modal from "../../components/utils/Modal";
import AddProductModal from "../../drawers/AddProductModal";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import SideDrawer from "../../components/utils/SideDrawer";
import api from "../../api";
import useCategoryView from "../../hooks/useCategoryView";
import Dropdown from "../../components/utils/Dropdown";
import Pagination from "../../components/utils/Pagination";

const Products = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { category } = useCategoryView();
  useEffect(()=>{
    if (category){
      if (!category?.some(cat => cat.name === 'all')) {
        category.push({id: '', name: 'all', description: '', image: '', slug: ''});
      }
    }
  },[category])
  
  
  const [selectedCategory, setSelectedCategory] = useState({});
  const [search, setSearch] = useState("");
  const { product, error, loading, setRefresh, pagination, setPage } = useProductView(search, selectedCategory);
  console.log(product, pagination, "_____01212010");

  const [prodId, setProdId] = useState("");
  const handleDeleteProduct = (id) => {
    setDeleteModal(true);
    setProdId(id);
    // api.post(`products/admin/delete-products/${id}`).then((res)=>{
    //   alert("Deleted Successfully");
    // });
  };
  const handleDelete = () => {
    api.post(`products/admin/delete-products/${prodId}/`).then((res) => {
      if (res.app_data.StatusCode === 6000) {
        alert("Deleted Successfully");
        setDeleteModal(false);
      }
    });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Price",
        accessor: "actual_price",
      },
      {
        Header: "purchase_price",
        accessor: "selling_price",
      },
      {
        Header: "Stock",
        accessor: "stock",
      },
      {
        Header: "Featured",
        accessor: "id",
        Cell: ({ row }) => {
          console.log(row, "_____hello world");
          const handleCheckboxChange = (e, id) => {
            console.log(e.target.checked);
            console.log(row.featured);
            api
              .post(`products/admin/add-to-featured/${id}/`, {
                featured: e.target.checked,
              })
              .then((res) => {
                setRefresh((prev) => !prev);
              });

            // api
            // Assuming you have a function to update the data when the checkbox changes
            // For example, updateData(row.original.id, e.target.checked);
          };

          return (
            <div>
              <input
                type="checkbox"
                checked={row.featured}
                // checked={row.values.featured}
                onChange={(e) => handleCheckboxChange(e, row.id)}
              />
            </div>
          );
        },
      },
      {
        Header: "Flash Sale",
        accessor: "id",
        Cell: ({ row }) => {
          console.log(row, "_____hello world");
          const handleCheckboxChange = (id) => {
            api.post(`products/admin/add-to-flashsale/${id}/`).then((res) => {
              setRefresh((prev) => !prev);
            });
          };

          return (
            <div>
              <input
                type="checkbox"
                checked={row.flash_sale}
                // checked={row.values.featured}
                onChange={() => handleCheckboxChange(row.id)}
              />
            </div>
          );
        },
      },
      {
        Header: "Published",
        accessor: "id",
        Cell: (row) => {
          console.log(row.row.published, "____hello ");
          const handleCheckboxChange = () => {
            api
              .post(`products/admin/update-publish/${row.row.id}/`, {
                published: !row.row.published,
              })
              .then((res) => {
                setRefresh((prev) => !prev);
              });
          };
          return (
            <div>
              <label className="switch">
                <input type="checkbox" checked={row.row.published} onChange={() => handleCheckboxChange(row.id)} />
                <span className="slider"></span>
              </label>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (row) => {
          console.log(row.row.id, "row_______");
          return (
            <div className="flex item-center justify-center gap-2">
              <span className="cursor-pointer" onClick={() => navigate(`/edit-product/${row.row.id}`)}>
                <FaEdit />
              </span>
              <span className="cursor-pointer" onClick={() => handleDeleteProduct(row.row.id)}>
                <MdDelete />
              </span>
              {/* <span className="cursor-pointer" onClick={()=>navigate(`/products/${row.row.id}`)}>
                <GrView />
              </span> */}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="text-white">
      <div className="py-3">
        <h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
          Products
        </h3>
      </div>
      <div className="bg-gray-800 rounded p-4 flex gap-5 justify-between">
        <div className="flex w-1/2 gap-4">
          <div className="w-1/2">
            <InputField placeholder={"Search Product"} onChange={(e) => setSearch(e.target.value)} value={search} />
          </div>
          <div className="w-1/2">
            <Dropdown options={category} selectedOption={selectedCategory} onSelect={(e) => setSelectedCategory(e)} />
          </div>
        </div>
        <div className="w-200 flex gap-2">
          {/* <button className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-emerald-500 border border-transparent active:bg-emerald-600 hover:bg-emerald-600 h-12 w-1/2 bg-emerald-700">
            Filter
          </button> */}
          <button
            className="align-bottom w-full inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-1/2 mr-3 flex items-center justify-center cursor-pointer h-12 px-4 md:py-1 py-2 h-12 text-sm bg-gray-700"
            onClick={() => navigate("/add-product")}
          >
            Add Product
          </button>
        </div>
      </div>
      <div className="bg-gray-800 rounded mt-3 overflow-hidden">
        {product && <Table columns={columns} data={product} pageSize={5} />}
        <div>
          <Pagination
            totalPages={pagination.total_pages}
            currentPage={pagination.current_page}
            onPageChange={(e) => setPage(e)}
          />
        </div>
      </div>
      <SideDrawer
        isOpen={modal}
        title="Add Product"
        onClose={() => {
          setModal((prev) => !prev);
        }}
      >
        <AddProductModal />
      </SideDrawer>
      <Modal
        isOpen={deleteModal}
        title="Delete Product"
        onClose={() => {
          setDeleteModal(false);
          setProdId("");
        }}
      >
        <div>
          <h5 className="text-center">Are you sure you want to delete this product?</h5>
        </div>
        <div className="flex justify-center gap-2 py-5">
          <button
            className="bg-gray-600 py-1 px-3 rounded-md"
            onClick={() => {
              setDeleteModal(false);
              setProdId("");
            }}
          >
            No
          </button>
          <button className="bg-red-500 py-1 px-3 rounded-md" onClick={() => handleDelete()}>
            Yes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Products;
